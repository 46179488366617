import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  ChangeEmailState,
  ChangePasswordState,
  ThunkApiConfig,
  UpdateLoginEmailArgs,
  UpdateLoginPasswordArgs,
} from '../../../types';
import { changePassword } from '@wix/ambassador-iam-authentication-v1-authentication/http';
import { updateIdentity } from '@wix/ambassador-iam-identity-v1-identity/http';
import { Interactions } from '../../../constants/interactions';

const isWrongPasswordError = (err: any): boolean =>
  err?.details?.applicationError?.code === '-19976';

const isEmailAlreadyExistsError = (err: any): boolean =>
  err?.details?.applicationError?.code === '-19995';

export const updateLoginEmail = createAsyncThunk<
  ChangeEmailState,
  UpdateLoginEmailArgs,
  ThunkApiConfig
>(
  'loginInfo/updateEmail',
  async (
    { newEmail, password },
    { getState, extra: { flowAPI, wixCodeApi } },
  ) => {
    flowAPI.fedops.interactionStarted(Interactions.LoginInfoEmailUpdate);
    const state = getState();
    try {
      // @ts-expect-error
      const identity = await wixCodeApi.members.authentication.login(
        state.member.loginEmail,
        password,
      );
      await flowAPI.httpClient.request(
        updateIdentity({
          fieldMask: ['email'],
          identity: {
            id: identity._id,
            revision: identity.revision,
            email: {
              address: newEmail,
            },
          },
        }),
      );
      flowAPI.fedops.interactionEnded(Interactions.LoginInfoEmailUpdate);
      return { stage: 'ok', newEmail };
    } catch (err: any) {
      if (isWrongPasswordError(err)) {
        flowAPI.fedops.interactionEnded(Interactions.LoginInfoEmailUpdate);
        return {
          stage: 'fail',
          errors: {
            password: flowAPI.translations.t(
              'app.widget.modals.change-email.password.error-wrong',
            ),
          },
        };
      }
      if (isEmailAlreadyExistsError(err)) {
        flowAPI.fedops.interactionEnded(Interactions.LoginInfoPasswordUpdate);
        return {
          stage: 'fail',
          errors: {
            newEmail: flowAPI.translations.t(
              'app.widget.modals.change-email.new-email.error-email-exists',
            ),
          },
        };
      }
      flowAPI.reportError(err);
      return { stage: 'fail' };
    }
  },
);

export const updateLoginPassword = createAsyncThunk<
  ChangePasswordState,
  UpdateLoginPasswordArgs,
  ThunkApiConfig
>(
  'loginInfo/updatePassword',
  async (
    { currentPassword, newPassword },
    { getState, extra: { flowAPI, wixCodeApi } },
  ) => {
    flowAPI.fedops.interactionStarted(Interactions.LoginInfoPasswordUpdate);
    const state = getState();
    try {
      await wixCodeApi.user.login(state.member.loginEmail, currentPassword);
      await flowAPI.httpClient.request(changePassword({ newPassword }));
      flowAPI.fedops.interactionEnded(Interactions.LoginInfoPasswordUpdate);
      return {
        stage: 'ok',
      };
    } catch (err: any) {
      if (isWrongPasswordError(err)) {
        flowAPI.fedops.interactionEnded(Interactions.LoginInfoPasswordUpdate);
        return {
          stage: 'fail',
          errors: {
            currentPassword: flowAPI.translations.t(
              'app.widget.modals.change-password.password.error-wrong',
            ),
          },
        };
      }
      flowAPI.reportError(err);
      return { stage: 'fail' };
    }
  },
);

export const recoverPassword = createAsyncThunk<void, void, ThunkApiConfig>(
  'loginInfo/recoverPassword',
  async (_, { rejectWithValue, getState, extra: { wixCodeApi, flowAPI } }) => {
    flowAPI.fedops.interactionStarted(Interactions.LoginInfoRecoverPassword);
    const state = getState();
    try {
      // @ts-expect-error
      await wixCodeApi.members.authentication.sendSetPasswordEmail(
        state.member.loginEmail,
      );
      flowAPI.fedops.interactionEnded(Interactions.LoginInfoRecoverPassword);
    } catch (err: any) {
      flowAPI.reportError(err);
      return rejectWithValue(err);
    }
  },
);

export const loginInfoThunk = {
  updateLoginEmail,
  updateLoginPassword,
  recoverPassword,
};
